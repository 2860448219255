import styled from "styled-components";

export const Wrapper = styled.div`
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    p,
    ul {
        margin: 0 0 10px;
    }
`;
