export function resolveBackground(
    images,
    defaultMobileImage = "/images/shapes/default-hero-bg-mobile.svg",
    defaultDesktopImage = "/images/shapes/default-hero-bg-desktop.svg"
) {
    let mobileImage = defaultMobileImage;
    let desktopImage = defaultDesktopImage;

    if (!Array.isArray(images)) {
        return { mobileImage, desktopImage };
    }

    if (images.length === 1) {
        return {
            mobileImage: images[0].url,
            desktopImage: images[0].url
        };
    }

    if (images?.length > 1) {
        images.forEach(image => {
            if (!image.url || image.url === "" || image.url === null) {
                return;
            }
            if (image.type === "MOBILE") {
                mobileImage = image.url;
            }
            if (image.type === "DESKTOP") {
                desktopImage = image.url;
            }
            if (image.type === "ALL") {
                mobileImage = image.url;
                desktopImage = image.url;
            }
        });
    }

    return { mobileImage, desktopImage };
}

export function resolveApiData(data, property) {
    if (data && data[property] && data[property] !== null) {
        return data[property];
    }

    return undefined;
}

export function resolveUsps(sections) {
    if (Array.isArray(sections) && sections.length > 0) {
        return sections.map(section => section.title);
    }

    return undefined;
}

export function resolveImages(images, mobile, desktop) {
    if (images && Array.isArray(images) && images.length > 0) {
        if (images.length === 1) {
            return { mobile: images[0], desktop: images[0] };
        }

        if (images.length > 1) {
            return { mobile: images[0], desktop: images[1] };
        }
    }

    return {
        mobile,
        desktop
    };
}
