import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveImages } from "helpers/page";

import {
    Container,
    Block,
    ImageWrapper,
    Content,
    Title,
    Description,
    StyledButton
} from "./DoubleCard.styled";

function DoubleCard({ title, description, link, images, fullWidth }) {
    const { mobileView } = useMediaQueryContext();
    const titleElement = fullWidth ? "h2" : "h3";

    const { mobile, desktop } = resolveImages(
        images,
        {
            url: "/images/doubler.png",
            alt: "Verdubbel de waarde van je kaart"
        },
        {
            url: "/images/doubler-large.png",
            alt: "Verdubbel de waarde van je kaart"
        }
    );

    const content = (
        <Content fullWidth={fullWidth}>
            <Title as={titleElement}>{title}</Title>
            <Description fullWidth={fullWidth}>{description}</Description>
            {link && (
                <Link href={link?.url} passHref prefetch={false}>
                    <StyledButton variant="tertiary" element="a">
                        {link?.label}
                    </StyledButton>
                </Link>
            )}
        </Content>
    );

    if (fullWidth) {
        return (
            <Container forwardedAs="section">
                <ImageWrapper>
                    {mobileView ? (
                        <Image
                            src={mobile?.url}
                            alt={mobile?.alt}
                            width={473}
                            height={300}
                            quality={75}
                            layout="responsive"
                        />
                    ) : (
                        <Image
                            src={desktop?.url}
                            alt={desktop?.alt}
                            width={500}
                            height={463}
                            quality={75}
                            layout="responsive"
                        />
                    )}
                </ImageWrapper>
                {content}
            </Container>
        );
    }

    return (
        <Block>
            <Image
                src={mobile?.url}
                alt={mobile?.alt}
                width={473}
                height={300}
                quality={75}
            />
            {content}
        </Block>
    );
}

DoubleCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    fullWidth: PropTypes.bool
};

DoubleCard.defaultProps = {
    title: "Verdubbel de waarde van je kaart",
    description:
        "Maak kans op een verdubbeling van het bedrag op je cadeaukaart. Vul meteen je kaartnummer in en doe mee!",
    link: {
        url: "/verdubbelaar/",
        label: "Ik doe mee!"
    },
    images: [
        {
            url: "/images/doubler.png",
            alt: "Verdubbel de waarde van je kaart"
        },
        {
            url: "/images/doubler-large.png",
            alt: "Verdubbel de waarde van je kaart"
        }
    ],
    fullWidth: false
};

export default DoubleCard;
