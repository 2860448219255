import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLazyQuery } from "@apollo/client/react/hooks";
import { useRouter } from "next/router";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import {
    formatPlaceDetails,
    parseGeolocation,
    parseLocations,
    parseMerchants,
    slugifyLocation
} from "helpers/quickSearch";

import { GetMerchantSuggestions } from "lib/api/cc/queries/merchants.gql";

import IconSearch from "components/ui/icons/IconSearch";

import MyLocation from "./myLocation/MyLocation";
import Suggestions from "./suggestions/Suggestions";

import {
    Container,
    Error,
    Field,
    InputWrapper,
    Label,
    SearchButton
} from "./QuickSearch.styled";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

async function getPlaceDetails(id) {
    if (!id) {
        return null;
    }

    const query = await fetch(`${baseUrl}/api/google-maps/details/${id}/`);
    const response = await query.json();

    return formatPlaceDetails(response?.result?.address_components);
}

async function getPlaces(input) {
    let places = [];

    if (input?.length > 0) {
        const query = await fetch(
            `${baseUrl}/api/google-maps/autocomplete/?input=${input}&types=(regions)`
        );
        const response = await query.json();

        places = parseLocations(response);
    }

    return places;
}

async function resolveUrl({ id, type, location, slug }) {
    if (type === "merchant" || type === "location") {
        return slugifyLocation(location, slug);
    }

    if (type === "suggestion" && id) {
        const placeDetails = await getPlaceDetails(id);
        return slugifyLocation(placeDetails);
    }

    return "/resorts/";
}

function QuickSearch({
    name,
    label,
    placeholder,
    size,
    showSubmitButton,
    geoLocationPosition,
    searchForMerchants,
    required,
    onClick,
    navigateOnClick,
    className
}) {
    const [merchants, setMerchants] = useState([]);
    const [locations, setLocations] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [isInputActive, setIsInputActive] = useState(false);
    const [suggestionIsOpen, setSuggestionIsOpen] = useState(false);
    const [lastInput, setLastInput] = useState("");
    const [location, setLocation] = useState({});
    const router = useRouter();
    const { mobileView } = useMediaQueryContext();

    const [getMerchantsByInput] = useLazyQuery(GetMerchantSuggestions, {
        onCompleted: data => {
            const suggestions = parseMerchants(data);
            setMerchants(suggestions);
        }
    });

    const { register, unregister, formState, watch, setValue } =
        useFormContext();
    const input = watch(name);
    const errorMessage = formState.errors?.[name]?.message;

    // Handle showing suggestions for MyLocation Component
    function setSuggestionsFromGeolocation(data) {
        const locations = parseGeolocation(data);
        setLocations(locations);

        if (data.length > 0) {
            setSuggestionIsOpen(true);
            setIsInputActive(true);
        }
    }

    // Hide suggestions on blur
    function handleBlur() {
        setIsInputActive(false);
        setTimeout(() => {
            setSuggestionIsOpen(false);
        }, 300);
    }

    async function onListItemClick(suggestion) {
        setSuggestionIsOpen(false);
        setLocation(suggestion);

        if (navigateOnClick) {
            const url = await resolveUrl(suggestion);
            await router.push(url);
        } else {
            setValue(name, suggestion.description);
            onClick(suggestion);
        }
    }

    async function getPlacesByInput(input) {
        const suggestions = await getPlaces(input);
        setLocations(suggestions);
    }

    // Create object in hook form for location.
    useEffect(() => {
        register({ name: "city" });
        register({ name: "province" });

        return () => {
            unregister("city");
            unregister("province");
        };
    }, [register, unregister]);

    // Set form values when location is selected
    useMemo(() => {
        if (location?.province) {
            setValue("province", location.province, {
                shouldDirty: true,
                shouldValidate: true
            });
        }
        if (location?.city) {
            setValue("city", location.city, {
                shouldDirty: true,
                shouldValidate: true
            });
        }
    }, [location, setValue]);

    // Clear location data if input changes
    useMemo(() => {
        if (input !== lastInput && lastInput !== "") {
            setLastInput(input);
            setLocation({});
        }
    }, [input, lastInput]);

    // Fetch merchants & google autocomplete places on change
    useMemo(() => {
        const hasFreshInput = input?.length > 0 && input !== lastInput;

        if (hasFreshInput) {
            const filter = {
                title: {
                    like: input
                }
            };

            getPlacesByInput(input);

            if (searchForMerchants !== false) {
                if (searchForMerchants === "privatespa") {
                    filter.private = true;
                }
                getMerchantsByInput({
                    variables: {
                        filter
                    }
                });
            }

            if (!suggestionIsOpen && lastInput !== "") {
                setSuggestionIsOpen(true);
            }

            setLastInput(input);
        }
    }, [
        input,
        lastInput,
        searchForMerchants,
        getMerchantsByInput,
        suggestionIsOpen
    ]);

    // Merge results of queries into suggestions to show
    useMemo(() => {
        setSuggestions([...locations, ...merchants]);
    }, [locations, merchants]);

    return (
        <Container size={size} className={className}>
            <Label size={size} for="search">
                {label}
            </Label>
            {geoLocationPosition === "top" && !mobileView && (
                <MyLocation
                    name={name}
                    setSuggestions={data => setSuggestionsFromGeolocation(data)}
                    position="top"
                />
            )}
            <InputWrapper
                size={size}
                isActive={isInputActive}
                suggestionIsOpen={suggestionIsOpen}
                hasError={!!errorMessage}
            >
                <Field
                    name={name}
                    id={`search-${name}`}
                    type="text"
                    defaultValue={input}
                    autoComplete="cc-number"
                    placeholder={placeholder}
                    maxLength="100"
                    size={size}
                    onBlur={() => handleBlur()}
                    onFocus={() => {
                        if (locations.length > 0) {
                            setSuggestionIsOpen(true);
                        }
                        setIsInputActive(true);
                    }}
                    suggestionIsOpen={suggestionIsOpen}
                    hasError={!!errorMessage}
                    showSubmitButton={showSubmitButton}
                    ref={register({
                        required:
                            required &&
                            "Vul een postcode, plaats of resortnaam in om te zoeken",
                        shouldUnregister: true
                    })}
                />
                {(geoLocationPosition === "inline" || mobileView) && (
                    <MyLocation
                        name={name}
                        setSuggestions={data =>
                            setSuggestionsFromGeolocation(data)
                        }
                        position={showSubmitButton ? "inline" : "right"}
                    />
                )}
                {showSubmitButton && (
                    <SearchButton
                        type="submit"
                        isActive={isInputActive}
                        title="Zoek naar resorts"
                        aria-label="Zoek naar resorts"
                        wider={geoLocationPosition === "top" && !mobileView}
                    >
                        <IconSearch size={25} fill="var(--color-gray-400)" />
                    </SearchButton>
                )}
                <Suggestions
                    name={name}
                    input={input}
                    suggestionIsOpen={suggestionIsOpen}
                    suggestions={suggestions}
                    onClick={suggestion => onListItemClick(suggestion)}
                />
            </InputWrapper>
            {errorMessage && <Error>{errorMessage}</Error>}
        </Container>
    );
}

QuickSearch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(["small", "large"]),
    showSubmitButton: PropTypes.bool,
    geoLocationPosition: PropTypes.oneOf(["inline", "top"]),
    searchForMerchants: PropTypes.oneOf(["all", "privatespa", false]),
    required: PropTypes.bool,
    navigateOnClick: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string
};

QuickSearch.defaultProps = {
    placeholder: "Postcode, plaats of naam",
    size: "large",
    geoLocationPosition: "top",
    showSubmitButton: true,
    searchForMerchants: "all",
    required: true,
    navigateOnClick: true,
    onClick: () => {},
    className: ""
};

export default QuickSearch;
