import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const InputWrapper = styled.div`
    margin-top: 10px;
`;

export const SuggestionsList = styled.ul`
    z-index: 9;

    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    list-style-type: none;
    background-color: var(--color-white);
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    box-shadow: 0 6px 7px 0 rgba(49, 49, 62, 0.19);

    margin-block-start: 0;
    padding-inline-start: 0;
`;
