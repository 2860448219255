import styled, { css } from "styled-components";
import media from "constants/media";

export const Container = styled.div`
    position: relative;

    display: flex;
    flex-flow: row wrap;
    gap: 20px 0;
    justify-content: space-between;
    max-height: 130px;

    ${media.lgUp} {
        gap: ${({ size }) => (size === "large" ? "30" : "15")}px 0;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    z-index: 20;

    width: 100%;
    height: 52px;
    margin-bottom: 0;

    border-bottom: 2px solid var(--color-red-600);

    transition: all var(--animation-duration-default) ease;

    ${({ isActive, suggestionIsOpen }) =>
        (isActive || suggestionIsOpen) &&
        css`
            border-color: transparent;
            border-radius: var(--border-radius-default);
            box-shadow: 0 3px 7px 0 rgba(49, 49, 62, 0.19);

            input {
                background-color: var(--color-white);
            }
        `}

    ${({ suggestionIsOpen }) =>
        suggestionIsOpen &&
        css`
            border-radius: var(--border-radius-default)
                var(--border-radius-default) 0 0;

            :after {
                position: absolute;
                top: 100%;
                left: 20px;

                width: calc(100% - 40px);
                height: 1px;

                border-bottom: 1px solid var(--color-peach-700);

                content: "";
            }
        `}

    input {
        border: none;
    }

    ${media.mdUp} {
        height: ${({ size }) => (size === "large" ? 67 : 52)}px;
    }
`;

export const SearchButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    padding: 0;

    background: transparent;
    border: none;
    cursor: pointer;
    opacity: ${({ isActive }) => (isActive ? "1" : "0.5")};

    transition: all var(--animation-duration-default) ease;

    :hover,
    :active,
    :focus {
        opacity: 1;

        svg {
            fill: var(--color-gray-500);
        }
    }

    ${media.mdUp} {
        width: ${({ wider }) => (wider ? 65 : 40)}px;
    }
`;

export const Label = styled.label`
    ${({ size }) =>
        size === "large"
            ? css`
                  display: none;
                  font-size: 20px;
                  line-height: 30px;

                  ${media.mdUp} {
                      display: block;
                      margin-bottom: 0;
                  }
              `
            : css`
                  font-size: 16px;
                  line-height: 20px;

                  ${media.mdUp} {
                      margin-bottom: 5px;
                  }
              `};
`;

export const Field = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: ${({ showSubmitButton }) =>
        showSubmitButton ? "0 100px 0 20px" : "0 50px 0 20px"};

    color: var(--color-gray-300);
    font-size: 14px;
    line-height: 20px;

    background-color: var(--color-white);
    border: none;
    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
    outline: 0;

    transition: all 0.3s ease-in-out;

    :placeholder-shown {
        overflow: hidden;

        text-overflow: ellipsis;
    }

    ::placeholder {
        opacity: 0.5;
    }

    ${media.mdUp} {
        height: ${({ size }) => (size === "large" ? 65 : 50)}px;
        padding: ${({ showSubmitButton }) =>
            showSubmitButton ? "0 80px 0 20px" : "0 60px 0 20px"};

        font-size: 15px;
        line-height: 30px;
    }
`;

export const Error = styled.p`
    position: absolute;
    bottom: -20px;
    left: 0;

    width: 100%;

    color: var(--color-red);
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;

    transform: translate(0, 100%);
`;
