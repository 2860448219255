import styled, { css } from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";
import Button from "components/ui/button/Button";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 75px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 20px;
        align-items: flex-start;
        margin-bottom: 80px;
        padding: 20px 10px 0;
    }

    ${media.lgUp} {
        padding: 60px 10px 0;
    }

    ${media.xlUp} {
        gap: 0 40px;
        margin-bottom: 65px;
    }
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 75px;

    ${media.lgUp} {
        margin-bottom: 0;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;

    flex: 0 0 auto;
    width: 100%;
    max-width: 500px;
    margin: 0;

    ${media.mdUp} {
        width: calc(50% - 10px);
        margin: -20px 0 0;
    }

    ${media.lgUp} {
        margin: -60px 0 0;
    }

    ${media.xlUp} {
        width: 500px;
    }
`;

export const Title = styled.h3`
    max-width: 80%;
    margin: 0;

    font-size: 22px;
    line-height: 30px;

    ${({ as }) =>
        as === "h2"
            ? css`
                  ${media.mdUp} {
                      max-width: 300px;
                      margin: 0 0 25px;

                      font-size: 25px;
                      line-height: 35px;
                  }

                  ${media.lgUp} {
                      max-width: 450px;

                      font-size: 40px;
                      line-height: 55px;
                  }
              `
            : css`
                  ${media.mdUp} {
                      max-width: unset;
                      margin: -35px 0 10px;

                      font-size: 25px;
                      line-height: 35px;
                  }
              `};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            ${media.mdUp} {
                align-items: flex-start;
                max-width: calc(50% - 10px);
                text-align: left;
            }
        `}
`;

export const Description = styled(ContentRenderer)`
    max-width: 90%;
    margin: 0 0 15px;

    ${media.mdUp} {
        margin: 0 0 30px;
    }

    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  ${media.lgUp} {
                      font-size: 20px;
                      font-family: var(--font-family-secondary);
                      line-height: 30px;
                  }

                  ${media.xlUp} {
                      max-width: 600px;
                  }
              `
            : css`
                  ${media.mdUp} {
                      max-width: 80%;
                  }
              `}
`;

export const StyledButton = styled(Button)`
    padding-right: 35px;
    padding-left: 35px;
`;
